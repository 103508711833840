import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/css/main.scss';
import store from './store'
import { createHead } from '@vueuse/head'

const app = createApp(App);
const head = createHead()

app.use(router).use(store).use(VueAxios, axios).use(head).mount('#app');
