import { createStore } from 'vuex'
import cart from './modules/cart'
import products from './modules/products'

const state = {
    productPopupData: null
}

const getters = {
    PRODUCT_POPUP_DATA(state) {
        return state.productPopupData
    },
}

const actions = {
    setProductPopupData(context, data) {
        context.commit('SET_PRODUCT_POPUP_DATA', data)
    },
}

const mutations = {
    SET_PRODUCT_POPUP_DATA(state, data) {
        state.productPopupData = data
    },
}

const store = createStore({
    modules: {
        cart,
        products
    },
    state,
    getters,
    actions,
    mutations
})

export default store