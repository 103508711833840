<template>
  <div id="app">
    <div class="site-container">
      <TheHeader />
      <div class="site-content">
        <div class="site-content-wrapper">
          <router-view />
        </div>
      </div>
      <TheFooter />
      <ProductPopup v-if="showProductPopup" />
    </div>
  </div>
</template>
<script>
import TheFooter from "./components/TheFooter.vue";
import TheHeader from "./components/TheHeader.vue";
import ProductPopup from "./components/ProductPopup.vue";
import { mapGetters } from "vuex";
import { useHead } from "@vueuse/head";

export default {
  name: "App",
  setup() {
    useHead({
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        },
      ],
    });
  },
  components: {
    TheHeader,
    TheFooter,
    ProductPopup,
  },
  computed: {
    ...mapGetters({
      showProductPopup: "PRODUCT_POPUP_DATA",
    }),
  },
  watch: {
    showProductPopup(n, o) {
      console.log(n);
      if (n) document.body.style.overflow = "hidden";
      else document.body.style.overflow = null;
    },
  },
};
</script>

