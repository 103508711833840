<template>
  <div class="footer">
    <div class="footer-wrapper">
      <div class="footer-container">
        <div class="footer-column">
          <div class="footer-text">
            On the other hand, we denounce with righteous indignation and
            dislike men who are so beguiled and demoralized by the charms of
            pleasure of the moment, so blinded by desire, that they cannot
            foresee the pain and trouble that are bound to ensue; and equal
            blame belongs to those who fail in their duty through weakness of
            will, which is the same as saying through shrinking from toil and
            pain. These cases are perfectly simple and easy to distinguish. In a
            free hour, when our power of choice is untrammelled and when nothing
            prevents our being able to do what we like best, every pleasure is
            to be welcomed and every pain avoided
          </div>
          <div class="social-network mobile-hidden">
            <div class="title">Мы в социальных сетях:</div>
            <div class="list">
              <a href="#" target="_blank">
                <img src="../assets/image/telegramm.png" alt="telegramm" />
              </a>
              <a href="#" target="_blank">
                <img src="../assets/image/tiktok.png" alt="tiktok" />
              </a>
              <a href="#" target="_blank">
                <img src="../assets/image/vk.png" alt="vk" />
              </a>
            </div>
          </div>
        </div>
        <div class="footer-column-mobile">
          <div class="footer-nav">
            <router-link to="/">Пользовательское соглашение</router-link>
            <router-link to="/"
              >Согласие на обработку персональных данных</router-link
            >
            <router-link to="/">Публичная оферта</router-link>
          </div>
          <div class="social-network mobile-visible">
            <div class="title">Мы в социальных сетях:</div>
            <div class="list">
              <a href="#" target="_blank">
                <img src="../assets/image/telegramm.png" alt="telegramm" />
              </a>
              <a href="#" target="_blank">
                <img src="../assets/image/tiktok.png" alt="tiktok" />
              </a>
              <a href="#" target="_blank">
                <img src="../assets/image/vk.png" alt="vk" />
              </a>
            </div>
          </div>
        </div>
        <div class="copyright">© 2023 Все права защищены</div>
      </div>
    </div>
  </div>
</template>
    
  <script>
export default {
  name: "TheFooter",
  components: {},
  data() {
    return {};
  },
};
</script>
    
<style lang="scss" scoped>
.footer {
  background: #edeff2;
  font-size: 14px;
  color: #888a8f;
  @media screen and (max-width: 1199px) {
    font-size: 12px;
  }
  &-wrapper {
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
  }
  &-container {
    position: relative;
    padding: 35px 10px;
    @media screen and (max-width: 1199px) {
      padding: 32px 16px;
    }
  }
  &-column {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 56px;
    margin-bottom: 40px;
    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
    &-mobile {
      @media screen and (max-width: 1199px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        & .social-network {
          padding-top: 4px;
        }
      }
    }
  }
  &-text {
  }
  &-nav {
    display: flex;
    flex-direction: column;
    & a {
      padding: 4px 0;
      display: inline-block;
      color: #888a8f;
      &:hover {
        color: #191b1e;
      }
    }
  }
}
.social-network {
  text-align: right;
  & .title {
    color: #191b1e;
    font-weight: 600;
    margin-bottom: 14px;
  }
  & .list {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    & a {
      display: flex;
    }
  }
}
.copyright {
  position: absolute;
  bottom: 35px;
  transform: translateX(50%);
  right: 50%;
  @media screen and (max-width: 1199px) {
    position: unset;
    width: fit-content;
    margin: 40px auto 0;
    transform: none;
  }
}
</style>
    