<template>
  <div class="header-wrapper">
    <div class="header">
      <div class="header-container">
        <dropdown-menu
          @opened="showMobileMenu = true"
          @closed="showMobileMenu = false"
          :overlay="false"
          :withDropdownCloser="true"
          class="mobile-menu-container"
        >
          <template #trigger>
            <button class="mobile-menu-button">
              <img src="../assets/image/mobile-menu.png" alt="menu" />
            </button>
          </template>
          <template #body>
            <div class="mobile-menu">
              <div class="mobile-menu-content">
                <div class="mobile-menu-header">
                  <router-link class="header-logo" to="/">
                    <img src="../assets/image/logo.svg" alt="logo" />
                  </router-link>
                  <button class="mobile-menu-close icon-button" dropdown-closer>
                    <img src="../assets/icons/close.svg" />
                  </button>
                </div>
                <div class="header-search mobile-menu-search">
                  <input
                    v-bind:value="searchText"
                    v-on:input="searchText = $event.target.value"
                    :class="{ openSearch: searchText.length > 0 }"
                    type="text"
                    placeholder="Искать товары"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="find"
                    @input="searchInput"
                  />
                  <button
                    class="icon-button clear"
                    v-if="searchText.length > 0"
                    @click="
                      searchText = '';
                      filterGroupId = null;
                      $store.dispatch('products/setSearchText', null);
                    "
                  >
                    <img src="../assets/icons/close.svg" alt="" />
                  </button>
                  <button
                    class="loupe"
                    :disabled="searchText.length < 1"
                    @click="find"
                    dropdown-closer
                  ></button>
                </div>

                <div class="mobile-menu-categories">
                  <div class="title">Категории товаров</div>
                  <div class="list">
                    <div
                      class="category"
                      :class="{ active: null === filterGroupId }"
                      @click="
                        filterGroupId = null;
                        $store.dispatch(
                          'products/setActiveFilterGroupId',
                          null
                        );
                      "
                      dropdown-closer
                    >
                      Все
                    </div>
                    <template
                      v-for="(item, key, index) in groupList"
                      :key="index"
                    >
                      <div
                        class="category"
                        :class="{ active: item.id === filterGroupId }"
                        @click="
                          filterGroupId = item.id;
                          $store.dispatch(
                            'products/setActiveFilterGroupId',
                            item.id
                          );
                        "
                        dropdown-closer
                      >
                        {{ item.name }}
                      </div>
                    </template>
                  </div>
                </div>

                <div class="mobile-menu-divider"></div>

                <router-link to="/">Каталог</router-link>
                <router-link to="/about">Доставка и оплата</router-link>
                <router-link to="/about">Контакты</router-link>
              </div>
            </div>
          </template>
        </dropdown-menu>
        <router-link class="header-logo" to="/">
          <img src="../assets/image/logo.svg" alt="logo" />
        </router-link>
        <div class="header-search mobile-hidden">
          <input
            v-bind:value="searchText"
            v-on:input="searchText = $event.target.value"
            :class="{ openSearch: searchText.length > 0 }"
            type="text"
            placeholder="Искать товары"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="find"
            @input="searchInput"
          />
          <button
            class="icon-button clear"
            v-if="searchText.length > 0"
            @click="
              searchText = '';
              $store.dispatch('products/setSearchText', null);
            "
          >
            <img src="../assets/icons/close.svg" alt="" />
          </button>
          <button
            class="loupe"
            :disabled="searchText.length < 1"
            @click="find"
          ></button>
        </div>
        <nav class="header-nav mobile-hidden">
          <router-link to="/">Каталог</router-link>
          <router-link to="/about">Доставка и оплата</router-link>
          <router-link to="/about">Контакты</router-link>
        </nav>
        <dropdown-menu mode="hover" class="header-shopping-cart-container">
          <template #trigger>
            <router-link to="/cart" class="header-shopping-cart">
              <img src="../assets/image/shopping-icon.png" alt="cart" />
              <div class="label mobile-hidden">Корзина</div>
              <div class="header-shopping-cart-count">
                {{ $store.getters["cart/cartTotalItem"] }}
              </div>
            </router-link>
          </template>
          <template #body>
            <div class="header-shopping-cart-list">
              <div v-if="$store.getters['cart/cartTotalItem'] > 0">
                <div
                  class="item"
                  v-for="(item, index) in $store.getters['cart/cartProducts']"
                  :key="index"
                >
                  <div class="name">{{ item.product.name }}</div>
                  <div class="price">
                    {{ item.quantity }} x {{ item.product.price }} ₽
                  </div>
                </div>
              </div>
              <div v-else>Корзина пуста</div>
            </div>
          </template>
        </dropdown-menu>
      </div>
      <div
        class="backdrop"
        :class="{
          show: showMobileMenu,
        }"
      ></div>
    </div>
  </div>
</template>
  
<script>
import DropdownMenu from "../../node_modules/v-dropdown-menu";
import "../../node_modules/v-dropdown-menu/dist/vue3/v-dropdown-menu.css";
import { mapGetters } from "vuex";

export default {
  name: "TheHeader",
  components: {
    DropdownMenu,
  },
  data() {
    return {
      showCart: false,
      showMobileMenu: false,
      searchText: "",
      filterGroupId: null,
    };
  },
  computed: {
    ...mapGetters({
      groupList: "products/GROUPS",
    }),
  },
  mounted() {
    if (localStorage.shoppingCart) {
      const shoppingCart = JSON.parse(localStorage.getItem("shoppingCart"));
      this.$store.dispatch("cart/updateCartItems", {
        items: shoppingCart,
      });
    }
  },
  methods: {
    searchInput() {
      this.searchText = this.searchText.replace(/[^a-zа-яё0-9, ' ']/gi, "");
      if (this.searchText.length === 0)
        this.$store.dispatch("products/setSearchText", null);
    },
    find() {
      if (this.searchText.length > 0) {
        this.filterGroupId = null;
        this.$store.dispatch("products/setSearchText", this.searchText);
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.header {
  max-width: 1290px;
  width: 100%;
  margin: 0 auto;
  &-wrapper {
    position: fixed;
    width: 100%;
    background: #ffffff;
    z-index: 10;
  }
  &-container {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    padding: 28px 10px;
    @media screen and (max-width: 1199px) {
      padding: 8px;
      box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &-logo {
    & img {
      height: 40px;
      @media screen and (max-width: 1199px) {
        height: 30px;
      }
    }
  }
  &-search {
    display: flex;
    align-items: center;
    flex: 0 0 40%;
    max-width: 40%;
    height: 50px;
    position: relative;
    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    &::before {
      content: " ";
      display: block;
      width: 1px;
      background: #bec2c7;
      position: absolute;
      top: 7px;
      bottom: 7px;
      right: 80px;
    }
    & input {
      width: 100%;
      font-size: 16px;
      padding: 5px 20px;
      line-height: 1.25;
      color: #495057;
      background-color: #fff;
      border: none;
      outline: none;
      border-radius: 8px;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
    & .loupe {
      min-width: 80px;
      height: 50px;
      background: transparent;
      background-image: url(../assets/image/search-icon.png);
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      background-size: 20px;
      color: transparent;
      &:not(:disabled) {
        cursor: pointer;
      }
    }
    & .clear {
      padding: 8px;
      & img {
        width: 20px;
      }
    }
  }
  &-nav {
    & a {
      display: inline-flex;
      align-items: center;
      height: 50px;
      padding: 0 20px;
      font-size: 16px;
      color: #191b1e;
      transition: color ease-in 0.2s;
      &:hover {
        color: #0345bf;
      }
      &.router-link-active {
        color: #0345bf;
      }
    }
  }
  &-shopping-cart {
    position: relative;
    height: 50px;
    width: 66px;
    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #191b1e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    @media screen and (max-width: 1199px) {
      box-shadow: none;
      width: 50px;
      height: 30px;
    }
    &-count {
      position: absolute;
      top: -4px;
      right: -8px;
      width: auto;
      height: 18px;
      line-height: 18px;
      font-size: 10px;
      text-align: center;
      padding: 0 6px;
      border-radius: 50px;
      background: #191b1e;
      color: #fff;
      @media screen and (max-width: 1199px) {
        top: 0px;
        right: 6px;
        height: 14px;
        line-height: 14px;
        font-size: 10px;
        padding: 0 4px;
      }
    }
    &-container {
      position: relative;
    }
    &-list {
      position: absolute;
      z-index: 99999;
      width: 250px;
      right: 0;
      top: 52px;
      height: auto;
      padding: 12px;
      background: #ffffff;
      box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      @media screen and (max-width: 1199px) {
        display: none;
      }
      & > div {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      & .item {
        display: grid;
        grid-template-columns: 3fr 1fr;
      }
    }
  }
}
.mobile-menu {
  width: 100%;
  max-width: 360px;
  height: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow-y: auto;
  max-height: 100vh;
  &-content {
    padding: 46px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  &-search {
    flex: unset;
    max-width: 100%;
    margin-bottom: 16px;
  }
  &-divider {
    width: 100%;
    border-top: 1px solid #bec2c7;
  }
  &-categories {
    & .title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    & .list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 16px;
      font-weight: 400;
      & .category {
        cursor: pointer;
        &.active {
          font-weight: 700;
        }
      }
    }
  }
  &-container {
    display: none;
    @media screen and (max-width: 1199px) {
      display: block;
    }
  }
  &-button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 0 4px;
    & img {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
  