import {
    serviceGetAsync,
    servicePostAsync,
    servicePatchAsync,
    serviceGetFullDataAsync,
    serviceDeleteAsync,
} from '../../api'

const state = {
    items: [],
    stocks: {},
    checkoutStatus: null
}

const getters = {
    cartProducts: (state) => {
        return state.items
    },

    cartTotalPrice: (state, getters) => {
        return getters.cartProducts.reduce((total, item) => {
            if (state.stocks[item.product.id] > 0)
                return total + item.product.price * item.quantity
            else return total
        }, 0)
    },

    cartTotalItem: (state, getters) => {
        return getters.cartProducts.reduce((total, item) => {
            if (state.stocks[item.product.id] > 0)
                return total + item.quantity
            else return total
        }, 0)
    },

    STOCKS(state) {
        return state.stocks
    },
}

const actions = {
    ['GET_STOCKS']: async ({ commit }) => {
        let respData = await serviceGetAsync(`product/getstocks`)
        commit('SET_STOCKS', respData)
        return respData.data
    },

    ['CONFIRM_PHONE']: async ({ commit }, { phone }) => {
        let respData = await serviceGetAsync(`verification/sendCode?number=${phone}`)
        return respData
    },

    ['CHECK_CODE']: async ({ commit }, { phone, code }) => {
        let respData = await serviceGetAsync(`verification/checkCode?number=${phone}&code=${code}`)
        return respData
    },

    ['CALC_DELIVERY_PRICE']: async ({ commit }, { data }) => {
        let respData = await servicePostAsync(`cdec/calcDeliveryPrice`, data)
        return respData
    },

    /* checkout({ commit, state }, products) {
        const savedCartItems = [...state.items]
        commit('setCheckoutStatus', null)
        // empty cart
        commit('setCartItems', { items: [] })
        shop.buyProducts(
            products,
            () => commit('setCheckoutStatus', 'successful'),
            () => {
                commit('setCheckoutStatus', 'failed')
                // rollback to the cart saved before sending the request
                commit('setCartItems', { items: savedCartItems })
            }
        )
    }, */

    updateCartItems({ state, commit }, items) {
        commit('setCartItems', items)
    },

    addProductToCart({ state, commit }, product) {
        commit('setCheckoutStatus', null)
        const productId = product.id
        const cartItem = state.items.find(item => item.product.id === productId)

        if (state.stocks[productId] > 0) {
            if (!cartItem) {
                commit('pushProductToCart', { product })
            } else if (state.stocks[productId] > cartItem.quantity) {
                commit('incrementItemQuantity', { product })
            }
            localStorage.setItem('shoppingCart', JSON.stringify(state.items))
        }
    },

    removeProductFromCart({ state, commit }, product) {
        commit('setCheckoutStatus', null)
        const cartItem = state.items.find(item => item.product.id === product.id)
        if (cartItem.quantity > 1) {
            commit('decrementItemQuantity', { product })
        } else {
            commit('pullProductFromCart', { product })
        }
        localStorage.setItem('shoppingCart', JSON.stringify(state.items))
    },
}

const mutations = {
    SET_STOCKS: (state, respData) => {
        state.stocks = respData
    },

    pushProductToCart(state, { product }) {
        state.items.push({
            product,
            quantity: 1
        })
    },

    incrementItemQuantity(state, { product }) {
        const cartItem = state.items.find(item => item.product.id === product.id)
        cartItem.quantity++
    },

    pullProductFromCart(state, { product }) {
        const cartItem = state.items.find(item => item.product.id === product.id)
        const index = state.items.indexOf(cartItem);
        if (index > -1) {
            state.items.splice(index, 1);
        }
    },

    decrementItemQuantity(state, { product }) {
        const cartItem = state.items.find(item => item.product.id === product.id)
        cartItem.quantity--
    },

    setCartItems(state, { items }) {
        state.items = items
    },

    setCheckoutStatus(state, status) {
        state.checkoutStatus = status
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}