import axios from 'axios'

const BASE_URL = 'https://dev.allstarlab.ru/api'

export let getAsync = async function (url) {
  try {
    const response = await axios.get(`${BASE_URL}${url}`)
    return response.data
  } catch (error) {
    // Error
    console.log('GET', error)
  }
  return null
}

export let postAsync = async function (url, obj) {
  try {
    const response = await axios.post(`${BASE_URL}${url}`, obj)
    return response
  } catch (error) {
    // Error
    console.log('POST', error)
    return error.response
  }
  // return null
}

export let putAsync = async function (url, obj) {
  try {
    const response = await axios.put(`${BASE_URL}${url}`, obj)
    return response
  } catch (error) {
    // Error
    console.log('PUT', error)
    return error.response
  }
  // return null
}

export let patchAsync = async function (url, obj) {
  try {
    const response = await axios.patch(`${BASE_URL}${url}`, obj)
    return response
  } catch (error) {
    // Error
    console.log('PATCH', error)
    return error.response
  }
  // return null
}

export let deleteAsync = async function (url, obj) {
  try {
    // const response = await axios.delete(`${BASE_URL}${url}`, obj)
    const response = await axios.delete(`${BASE_URL}${url}`, { data: obj })
    return response
  } catch (error) {
    // Error
    console.log('DELETE', error)
    return error.response
  }
  // return null
}

/* GET-запрос ко внутреннему сервису */
export async function serviceGetAsync(action, parameters) {
  return await getAsync(`/${action}`)
}

/* POST-запрос ко внутреннему сервису */
export async function servicePostAsync(action, data) {
  return await postAsync(`/${action}`, data)
}

/* PUT-запрос ко внутреннему сервису */
export async function servicePutAsync(action, data) {
  return await putAsync(`/${action}`, data)
}

/* PATCH-запрос ко внутреннему сервису */
export async function servicePatchAsync(action, data) {
  return await patchAsync(`/${action}`, data)
}

/* DELETE-запрос ко внутреннему сервису */
export async function serviceDeleteAsync(action, data) {
  return await deleteAsync(`/${action}`, data)
}
