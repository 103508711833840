import {
    serviceGetAsync,
    servicePostAsync,
    servicePatchAsync,
    serviceGetFullDataAsync,
    serviceDeleteAsync,
} from '../../api'

const state = {
    attributeList: [],
    groupList: [],
    searchText: null,
    activeFilterGroupId: null,
}

const getters = {
    GROUPS(state) {
        return state.groupList
    },
    ATTRIBUTES(state) {
        return state.attributeList
    },
    SEARCH_TEXT(state) {
        return state.searchText
    },
    ACTIVE_FILTER_GROUP_ID(state) {
        return state.activeFilterGroupId
    },
}

const actions = {
    ['GET_PRODUCTS']: async ({ commit }, { data }) => {
        let respData = await servicePostAsync(
            `product`, data
        )
        return respData.data
    },
    ['SYNC_PRODUCTS']: async ({ commit }) => {
        let respData = await serviceGetAsync(
            `product/sync`
        )
        return respData
    },
    ['GET_GROUPS']: async ({ commit }) => {
        let respData = await serviceGetAsync(
            `group`
        )
        commit('SET_GROUPS', respData)
        return respData
    },
    ['GET_ATTRIBUTES']: async ({ commit }) => {
        let respData = await serviceGetAsync(
            `attribute`
        )
        commit('SET_ATTRIBUTES', respData)
        return respData
    },
    setSearchText(context, data) {
        context.commit('SET_SEARCH_TEXT', data)
    },
    setActiveFilterGroupId(context, data) {
        context.commit('SET_ACTIVE_FILTER_GROUP_ID', data)
    },
}

const mutations = {
    SET_GROUPS: (state, respData) => {
        state.groupList = respData
    },
    SET_ATTRIBUTES: (state, respData) => {
        state.attributeList = respData
    },
    SET_SEARCH_TEXT(state, respData) {
        state.searchText = respData
    },
    SET_ACTIVE_FILTER_GROUP_ID(state, respData) {
        state.activeFilterGroupId = respData
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}