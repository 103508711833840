<template>
  <div class="overlay-container">
    <div class="popup-container">
      <button class="popup-close icon-button" @click="closePopup()">
        <img src="../assets/icons/close.svg" />
      </button>
      <div class="product">
        <div class="product-version">
          <div v-for="(productVersion, index) in product.items" :key="index">
            <template
              v-if="productVersion.volume > 0 || productVersion.weight > 0"
            >
              <input
                type="radio"
                :id="productVersion.id"
                :value="productVersion"
                v-model="product.active"
              />
              <label :for="productVersion.id">
                {{
                  productVersion.volume > 0
                    ? productVersion.volume + " ml"
                    : productVersion.weight + " g"
                }}
              </label>
            </template>
          </div>
        </div>
        <div class="swiper-container" v-if="product.active.images.length > 0">
          <swiper
            :loop="true"
            :pagination="{
              clickable: true,
            }"
            :modules="modules"
            class="productSlider"
          >
            <swiper-slide
              v-for="(image, index) in product.active.images"
              :key="index"
            >
              <img class="productImage" :src="'data:' + image" alt="image" />
            </swiper-slide>
          </swiper>
        </div>
        <img
          class="productImage noImage"
          v-else
          src="../assets/image/noImage.png"
          alt=""
        />
        <div>
          <div class="name">
            {{ product.name }}
          </div>
          <div v-if="product.active.volume > 0" class="volume">
            Объем: {{ product.active.volume }}ml
          </div>
          <div v-if="product.active.weight > 0" class="volume">
            Вес: {{ product.active.weight }}g
          </div>
          <div class="price">{{ product.active.price }} ₽</div>

          <div v-if="!inStock" class="notInStock">Нет в наличии</div>

          <div
            class="addToCart multiButton"
            v-if="
              $store.getters['cart/cartProducts'].some(
                (item) => item.product.id === product.active.id
              )
            "
          >
            <button
              @click.prevent.stop="
                $store.dispatch('cart/removeProductFromCart', product.active)
              "
            >
              -
            </button>
            <div class="quantity-product">
              {{
                $store.getters["cart/cartProducts"].find(
                  (item) => item.product.id === product.active.id
                ).quantity
              }}шт
            </div>
            <button
              :disabled="!inStock"
              @click.prevent.stop="
                $store.dispatch('cart/addProductToCart', product.active)
              "
            >
              +
            </button>
          </div>
          <button
            v-else
            class="primary-button addToCart"
            :disabled="!inStock"
            @click.prevent.stop="
              $store.dispatch('cart/addProductToCart', product.active)
            "
          >
            В корзину
          </button>

          <div class="divider"></div>

          <div class="scrollContent">
            <div class="description">
              <div class="title">Описание</div>
              <div class="content">{{ product.active.description }}</div>
            </div>
            <div class="items">
              <div class="title">Коротко о товаре</div>
              <div class="list">
                <div
                  class="item"
                  v-for="(value, key, index) in product.active.data"
                  :key="index"
                >
                  <div class="key">
                    <span>{{ getAttributeName(key) }}</span>
                  </div>
                  <div class="value">{{ value }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
export default {
  name: "ProductPopup",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  data() {
    return {
      product: this.$store.state.productPopupData,
      attributeList: this.$store.getters["products/ATTRIBUTES"],
    };
  },
  computed: {
    inStock() {
      if (this.$store.getters["cart/STOCKS"]) {
        const id = this.product.active.id;
        if (this.$store.getters["cart/STOCKS"][id] > 0) return true;
      }
      return false;
    },
  },
  methods: {
    getAttributeName(id) {
      return this.attributeList.find((attribute) => attribute.id === id).name;
    },
    closePopup() {
      this.$store.dispatch("setProductPopupData", null);
    },
  },
};
</script>
    
<style lang="scss" scoped>
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(17, 20, 45, 0.6);
}
.popup {
  &-container {
    position: relative;
    border-radius: 12px;
    background-color: #ffffff;
    background-repeat: no-repeat;
    pointer-events: auto;
    max-width: 880px;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;

    @media screen and (max-width: 1199px) {
      max-width: 580px;
    }
    @media screen and (max-width: 580px) {
      border-radius: 0;
    }
  }
  &-close {
    color: #11142d;
    position: absolute;
    top: 24px;
    right: 24px;
    transition: transform ease-in 0.2s;
    @media screen and (max-width: 1199px) {
      //top: 64px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}
.product {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 40px 30px;
  @media screen and (max-width: 1199px) {
    grid-template-columns: 1fr;
    padding: 56px 24px 24px;
  }
  &-version {
    width: auto;
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 2;
    @media screen and (max-width: 1199px) {
      top: 48px;
    }
    & input {
      display: none;
      &:checked + label {
        background: #191b1e;
        color: #fff;
      }
      &:not(:checked):hover + label {
        background: #efefef;
      }
    }
    & label {
      border: 1.5px solid #191b1e;
      background: #fff;
      border-radius: 2px 15px 15px 15px;
      width: 54px;
      height: 30px;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      white-space: nowrap;
      color: #191b1e;
      transition: all ease-in 0.1s;
    }
  }
  & .swiper-container {
    height: 100%;
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 100vw;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      max-height: 300px;
    }
  }

  & .productSlider {
    & :deep() {
      .swiper-slide {
        width: 100% !important;
        flex-shrink: 0;
        display: block;
        height: 100%;
        max-height: 100%;
      }
      .swiper-pagination-bullets.swiper-pagination-horizontal {
        top: unset;
        bottom: -5px;
      }

      .swiper-wrapper {
        max-height: 100%;
        height: 100%;
        display: flex;
      }
    }
  }
  & .productImage {
    object-fit: cover;
    width: 100%;
    margin-bottom: 12px;
    @media screen and (max-width: 1199px) {
      max-height: 300px;
    }
    &.noImage {
      margin-bottom: 30px;
    }
  }
  & .price {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  & .name {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  & .volume {
    font-size: 16px;
    font-weight: 400;
    color: #888a8f;
    margin-bottom: 20px;
  }
  & .notInStock {
    font-size: 16px;
    font-weight: 400;
    color: #888a8f;
    margin-bottom: 12px;
  }
  & .addToCart {
    &.multiButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 2px solid #191b1e;
      background: #fff;
      border-radius: 7px;
      height: 42px;
      width: 100%;
      font-size: 16px;
      &:hover {
        cursor: default;
      }
      & button {
        background: none;
        border: none;
        outline: none;
        font-size: 32px;
        font-weight: 300;
        flex: 0 0 auto;
        width: 30%;
        &:hover {
          cursor: pointer;
        }
        &:disabled {
          cursor: default !important;
          opacity: 0.5;
        }
      }
    }
  }

  & .divider {
    border-bottom: 1px solid #edeff2;
    width: 100%;
    margin: 16px 0;
  }

  & .scrollContent {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-color: #808191 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      background: #808191;
      border-radius: 14px;
    }
    @media screen and (max-width: 1199px) {
      max-height: unset;
    }
  }

  & .description {
    & .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    & .content {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
  & .items {
    & .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    & .item {
      display: grid;
      grid-template-columns: 3fr 2fr;
      & .key {
        font-size: 13px;
        font-weight: 700;
        color: #888a8f;
        display: flex;
        & span {
          flex: 0 0 auto;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          border-bottom: 1.5px dotted #888a8f;
          margin: 6px 3px;
        }
      }
      & .value {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
}
</style>
    